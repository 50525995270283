import Header from "./Header";

export function NoPermission(props) {
    const { isAdmin, user, notification, clearNotifications } = props;

    return (
        <div>
            <Header
                isAdmin={isAdmin}
                user={user}
                notification={notification}
                clearNotifications={clearNotifications} />
                <div  className='warning-message'>You are not authorized to view this page</div>
        </div>

    )
}