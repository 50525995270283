import React, { useCallback } from 'react'
import { useState } from 'react';
import { useEffect } from 'react';
import Service from '../../service/Service';
import Table from 'react-bootstrap/Table';
import { NavLink } from "react-router-dom";
import Header from './Header';
import { NotificationContainer, NotificationManager } from 'react-notifications';
import SweetAlert from 'react-bootstrap-sweetalert';
import { ThreeDots } from 'react-loader-spinner';
import loader from '../../images/loading.gif';
import { useOktaAuth } from "@okta/okta-react";
import { NoPermission } from './NoPermission';

const Profile = () => {
    const [isOpen, setOpen] = useState(false);
    const [subscriptionId, setSubscriptionId] = useState("");
    let [user, setUser] = useState({});
    let [list, setList] = useState([]);
    let [loading, setLoading] = useState(false);
    const [notification, setNotification] = useState([]);
    const clearNotifications = () => { setNotification([]); }
    const { oktaAuth } = useOktaAuth();
    const accessToken = oktaAuth.getAccessToken();
    const [isAdmin, setIsAdmin] = useState(false);

    const getAllSubscriptions = useCallback(() => {
        Service.getAllSubscriptions(accessToken).then((res) => {
            setList(res);
            setLoading(false);
        });
    }, [accessToken]);

    const checkIsAdmin = useCallback((list, email) => {
        for (let i = 0; i < list.length; i++) {
            if (list[i].email === email && !isAdmin) setIsAdmin(true);
        }
    }, [isAdmin])

    const getAdmins = useCallback((email) => {
        Service.getAdmins(accessToken).then((res) => {
            let defaultAdmins = res.defaultAdmins;
            checkIsAdmin(defaultAdmins, email);
            if (res?.emails?.adminEmails && !isAdmin) {
                let adminEmails = res.emails.adminEmails;
                checkIsAdmin(adminEmails, email);
            }
        });
    }, [accessToken, checkIsAdmin, isAdmin]);

    const removeNotification = useCallback((id) => {
        setNotification(notification.filter(val => val.id !== id));
    }, [notification])

    const refresh = useCallback((id, name) => {
        Service.refreshSubscription(id, accessToken).then((res) => {
            if (res.status === 'Subscribed') {
                NotificationManager.success(`Subscription updated successfully with Id: ${id}`);
                getAllSubscriptions();
                removeNotification(id);
            } else {
                let timer2 = setTimeout(() => refresh(id), 30000);
                return () => clearTimeout(timer2);
            }
        });
    }, [accessToken, getAllSubscriptions, removeNotification]);

    const addNotification = useCallback((id, name) => {
        let val = [{
            "id": id,
            "message": `Subscription ${name} with id ${id} activation is in progress`,
            "image": loader,
        }];
        setNotification((data) => ([...data, ...val]))
    }, []);

    const activate = useCallback((e, id, name) => {
        e.preventDefault();
        setLoading(true);
        Service.activateSubscription(id, accessToken).then((res) => {
            refresh(id, name);
            addNotification(id, name);
            setSubscriptionId(id);
            let timer1 = setTimeout(() => {
                getAllSubscriptions();
                setOpen(true);
            }
                , 5000);
            return () => clearTimeout(timer1);
        });
    }, [accessToken, addNotification, getAllSubscriptions, refresh])

    const hardRefresh = useCallback((e, id) => {
        e.preventDefault();
        setLoading(true);
        Service.refreshSubscription(id, accessToken).then((res) => {
            getAllSubscriptions();
        });
    }, [accessToken, getAllSubscriptions]);

    useEffect(() => {
        setLoading(true);
        const userData = JSON.parse(localStorage.getItem('okta-token-storage')).idToken.claims;
        setUser(userData);
        getAdmins(userData.preferred_username);
        getAllSubscriptions();
    }, [getAdmins, getAllSubscriptions]);

    if (!isAdmin && !loading) {
        return <NoPermission
            isAdmin={isAdmin}
            user={user}
            notification={notification}
            clearNotifications={clearNotifications}
        />
    }

    return (
        <div>
            {
                loading ?
                    (<div className='overlay'>
                        <ThreeDots
                            height="80"
                            width="80"
                            radius="9"
                            color="black"
                            ariaLabel="loading"
                            wrapperStyle
                            wrapperClass="loader" />
                    </div>)
                    : (<></>)}
            <>
                <Header isAdmin={isAdmin} user={user} notification={notification} clearNotifications={clearNotifications} />
                <div style={{ paddingLeft: "30px", paddingTop: "30px" }}>
                    <div>
                        <h3>Manage All Subscriptions</h3>
                        <Table striped hover size="sm">
                            <thead>
                                <tr>
                                    <th className='col-width'>Subscription Name</th>
                                    <th className='col-width'>Asset ID</th>
                                    <th className='col-width'>Company</th>
                                    <th className='col-width'>Purchaser Email</th>
                                    <th className='col-width'>Status</th>
                                    <th className='col-width'>Action</th>
                                </tr>
                            </thead>
                            <tbody>
                                {list?.map((val, key) => {
                                    return (
                                        <tr key={key}>
                                            <td className='col-width'>
                                                <NavLink style={{ textDecoration: 'none' }}
                                                    to={{
                                                        pathname: "/app/details/",
                                                        data: {
                                                            subDetails: val,
                                                            user: user
                                                        }
                                                    }}>{val.name}</NavLink>
                                            </td>
                                            <td style={{ width: '350px' }} >
                                                {val.subscription_id}
                                            </td>
                                            <td style={{ width: '350px' }} >
                                                {val.company}
                                            </td>
                                            <td className='col-width'>
                                                {val.purchaser_email}
                                            </td>
                                            <td className='col-width'>
                                                {val.status}
                                                {
                                                    val.is_activated === 'true' ?
                                                        <span style={{ cursor: 'pointer' }} title="Refresh status" onClick={e => hardRefresh(e, val.subscription_id)}>
                                                            <i style={{ fontSize: '15px', padding: '5px' }} class="fa fa-refresh" aria-hidden="true"></i>
                                                        </span>
                                                        : null
                                                }
                                            </td>
                                            <td className='col-width'>
                                                {
                                                    val.is_activated !== 'true' && isAdmin ?
                                                        <NavLink style={{ textDecoration: 'none' }}
                                                            to={{
                                                                pathname: "/app/publisher/",
                                                            }}>
                                                            <span onClick={e => activate(e, val.subscription_id, val.name)}>Activate</span>
                                                        </NavLink>
                                                        : null
                                                }
                                            </td>
                                        </tr>
                                    )
                                })}
                            </tbody>
                        </Table>
                    </div>
                    <SweetAlert
                        success
                        title="Activated"
                        show={isOpen}
                        onConfirm={() => {
                            setOpen(false)
                        }}
                    >
                        Asset with ID {subscriptionId} has been successfully Activated.
                    </SweetAlert>
                    <NotificationContainer />
                </div>

            </>
        </div >
    )
}

export default Profile;
